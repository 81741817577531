import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const fetchWithCredentials = (url, props) => {
    const token = localStorage.getItem('token');

    return fetch(process.env.REACT_APP_API + url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      ...props
    })
    .then(response => {
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          console.log('Unauthorized - Redirecting or logging out');
          completeLogout();
        }
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
}

export const postWithCredentials = (url, props) => {
    const token = localStorage.getItem('token');

    return fetch(`${process.env.REACT_APP_API + url}`, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Authorization': `Bearer ${token}`,
            // 'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        ...props
    })
    .then(response => {
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          console.log('Unauthorized - Redirecting or logging out');
          completeLogout();
        }
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
}

export const completeLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
}

export const WithAuth = (Page, permissions) => {
  return function WithAuthWrapper() {
    const token = localStorage.getItem('token');
    const [authenticated, setAuthenticated] = useState(!!token);

    useEffect(() => {
      setAuthenticated(!!token);
    }, [token]);

    if (!authenticated) {
      window.location.href = '/login'
      return <Unauthorized />;
    }

    const account = decodeJwt(localStorage.getItem('token'))

    if (!permissions) return <Page />;
    if (account.admin || account.owner || permissions.some(permission => account.permissions[permission])) {
      return <Page />;
    } else {
      return <Unauthorized />;
    }
  };
};

export const parseDateString = (dateString) => {
  const months = {
    "Jan": 0, "Feb": 1, "Mar": 2, "Apr": 3, "May": 4, "Jun": 5,
    "Jul": 6, "Aug": 7, "Sep": 8, "Oct": 9, "Nov": 10, "Dec": 11
  };

  const dateArray = dateString.split(" ");

  const day = parseInt(dateArray[2]);
  const year = parseInt(dateArray[3]);
  const month = months[dateArray[1]];

  const date = new Date(year, month, day);

  return date;
}

const Unauthorized = () => {
  return (
    <div>
      <h1>Unauthorized</h1>
    </div>
  );
};

const decodeJwt = (token) => {
  const parts = token.split('.');
  const decodedPayload = decodeURIComponent(atob(parts[1].replace(/-/g, '+').replace(/_/g, '/')).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  const decodedPayloadObject = JSON.parse(decodedPayload);

  return decodedPayloadObject;
}

export const generateTicketSummary = (ticketArray) => {
  const ticketCounts = {};

  // Count the occurrences of each ticket name
  ticketArray.forEach(ticket => {
      if (ticket.name in ticketCounts) {
          ticketCounts[ticket.name]++;
      } else {
          ticketCounts[ticket.name] = 1;
      }
  });

  // Generate the summary string
  const summary = Object.entries(ticketCounts).map(([name, count]) => `${count}x ${name}`).join(', ');

  return summary;
}