import { fetchWithCredentials } from '../utils/utils';

/**
 * Get a new Stripe Connect account link for onboarding
 * @returns {Promise<{url: string, expires_at: number}>}
 */
export const getStripeAccountLink = async () => {
  const response = await fetchWithCredentials('/api/whoppa/stripe/account-link', {
    method: 'POST'
  });
  return response;
};

/**
 * Get the current Stripe Connect account status
 * @returns {Promise<{
 *   charges_enabled: boolean,
 *   payouts_enabled: boolean,
 *   details_submitted: boolean,
 *   requirements: {
 *     currently_due: string[],
 *     eventually_due: string[],
 *     past_due: string[]
 *   }
 * }>}
 */
export const getStripeAccountStatus = async () => {
  const response = await fetchWithCredentials('/api/whoppa/stripe/account-status', {
    method: 'GET'
  });
  return response;
}; 