import React, { useState, useRef, useEffect } from 'react';

const FloorPlan = ({ tables, textElements = [], onTableSelect }) => {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [showControls, setShowControls] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);
  const lastPosition = useRef({ x: 0, y: 0 });
  const touchDistance = useRef(0);

  const getTableColor = (status) => {
    switch (status) {
      case 'available':
        return 'border-green-500 hover:opacity-75';
      case 'reserved':
        return 'border-orange-400';
      case 'ordered':
        return 'border-red-500 border-opacity-50';
      default:
        return 'border-gray-500';
    }
  };

  // Position helper for both tables and text elements
  const getPosition = (element) => {
    return {
      left: `${element.x}%`,
      top: `${element.y}%`,
      ...(element.width && { width: `${element.width}%` }),
      ...(element.height && { height: `${element.height}%` }),
    };
  };

  const handleMouseDown = (e) => {
    if (e.button === 0) {
      setIsDragging(true);
      lastPosition.current = {
        x: e.clientX - position.x,
        y: e.clientY - position.y
      };
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - lastPosition.current.x,
        y: e.clientY - lastPosition.current.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleWheel = (e) => {
    e.preventDefault();
    const delta = e.deltaY * -0.002;
    const newScale = Math.min(Math.max(1, scale + delta), 4);
    setScale(newScale);
  };

  const handleTouchStart = (e) => {
    if (e.touches.length === 2) {
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      touchDistance.current = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
    } else if (e.touches.length === 1) {
      setIsDragging(true);
      lastPosition.current = {
        x: e.touches[0].clientX - position.x,
        y: e.touches[0].clientY - position.y
      };
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 2) {
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const currentDistance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );

      const delta = (currentDistance - touchDistance.current) * 0.01;
      touchDistance.current = currentDistance;

      const newScale = Math.min(Math.max(1, scale + delta), 4);
      setScale(newScale);
    } else if (isDragging) {
      setPosition({
        x: e.touches[0].clientX - lastPosition.current.x,
        y: e.touches[0].clientY - lastPosition.current.y
      });
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const move = (direction) => {
    const step = 50;
    switch (direction) {
      case 'up':
        setPosition(prev => ({ ...prev, y: prev.y + step }));
        break;
      case 'down':
        setPosition(prev => ({ ...prev, y: prev.y - step }));
        break;
      case 'left':
        setPosition(prev => ({ ...prev, x: prev.x + step }));
        break;
      case 'right':
        setPosition(prev => ({ ...prev, x: prev.x - step }));
        break;
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('wheel', handleWheel, { passive: false });
    }
    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel);
      }
    };
  }, [scale]);

  return (
    <div className="relative overflow-hidden w-full h-full border border-gray-200 rounded-lg">
      {/* Toggle Controls Button */}
      <button
        className="absolute top-4 right-4 z-10 bg-gray-100 rounded-lg shadow-md p-2 hover:bg-gray-50"
        onClick={() => setShowControls(!showControls)}
      >
        {showControls ? '✕' : '⚙️'}
      </button>

      {/* Controls Panel */}
      {showControls && (
        <div className="absolute top-16 right-4 z-10 bg-gray-100 rounded-lg shadow-md p-2 space-y-2">
          <div className="flex gap-2 justify-center">
            <button
              className="px-3 py-1 bg-white hover:bg-gray-200 rounded"
              onClick={() => setScale(scale => Math.min(scale + 0.2, 4))}
            >
              +
            </button>
            <button
              className={`px-3 py-1 rounded
                ${scale <= 1 
                  ? 'bg-gray-50 text-gray-400 cursor-not-allowed' 
                  : 'bg-white hover:bg-gray-200'
                }`}
              onClick={() => setScale(scale => Math.max(scale - 0.2, 1))}
              disabled={scale <= 1}
            >
              -
            </button>
          </div>

          <div className="grid grid-cols-3 gap-1">
            <div></div>
            <button
              className="p-2 bg-white hover:bg-gray-200 rounded"
              onClick={() => move('up')}
            >
              ↑
            </button>
            <div></div>
            <button
              className="p-2 bg-white hover:bg-gray-200 rounded"
              onClick={() => move('left')}
            >
              ←
            </button>
            <button
              className="p-2 bg-white hover:bg-gray-200 rounded text-sm"
              onClick={() => {
                setScale(1);
                setPosition({ x: 0, y: 0 });
              }}
            >
              Reset
            </button>
            <button
              className="p-2 bg-white hover:bg-gray-200 rounded"
              onClick={() => move('right')}
            >
              →
            </button>
            <div></div>
            <button
              className="p-2 bg-white hover:bg-gray-200 rounded"
              onClick={() => move('down')}
            >
              ↓
            </button>
            <div></div>
          </div>
        </div>
      )}

      {/* Floor plan container */}
      <div
        ref={containerRef}
        className="relative w-full bg-gray-100"
        style={{
          transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
          transformOrigin: '0 0',
          transition: isDragging ? 'none' : 'transform 0.3s',
          cursor: isDragging ? 'grabbing' : 'grab',
          aspectRatio: '16/9'
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {/* Text Elements */}
        {textElements.map((text) => (
          <div
            key={text.id}
            className="table-name absolute text-white select-none"
            style={{
              ...getPosition(text),
              color: `${text.color || 'black'}`,
              fontSize: `${text.fontSize || 1}rem`,
              fontWeight: text.bold ? 'bold' : 'normal',
              transform: `rotate(${text.rotation || 0}deg)`,
            }}
          >
            {text.content}
          </div>
        ))}

        {/* Tables */}
        {tables.map((table) => (
          <div
            key={table.id}
            className={`absolute cursor-pointer rounded border-2 bg-white ${getTableColor(table.status)}
              transition-opacity`}
            style={getPosition(table)}
            onClick={(e) => {
              e.stopPropagation();
              table.status === 'available' && onTableSelect(table);
            }}
          >
            {table.status === 'reserved' && (
              <span className="absolute inset-0 flex flex-col items-center justify-center text-gray-900 font-bold select-none text-center">
                Reserved for {table.timeLeft} minutes
              </span>
            )}
            {table.status === 'ordered' && (
              <span className="absolute inset-0 flex flex-col items-center justify-center text-gray-900 font-bold select-none text-center">
                Unavailable
              </span>
            )}
            <span className={`table-name ${table.status !== 'available' && 'opacity-30'} absolute inset-0 flex flex-col items-center justify-center text-gray-900 font-bold select-none text-center`}>
              {table.name}
              {!table.hidePrice && (
                <>
                <br />  
                €{table.price}
                </>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FloorPlan;