import Navbar from '../../components/navbar/Navbar';
import { useEffect } from 'react';
import Footer from '../../components/footer/Footer';
import { setEvents } from '../../redux/infoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWithCredentials, WithAuth } from '../../utils/utils';
import NavbarMobile from '../../components/navbar/NavbarMobile';
import ReservationsEventsTable from '../../components/reservations-events-table/ReservationsEventsTable';

const Reservations = () => {
    const events = useSelector((state) => state.infoReducer.events)
    const dispatch = useDispatch();

    useEffect(() => {
        getEvents()
    }, [])

    const getEvents = () => {
        fetchWithCredentials(`/api/whoppa/getEvents`, {})
        .then(response => {
            dispatch(setEvents(response.content.filter(event => event.totalTables > 0)))
        })
    }

    return (
        <div className="Events">
            <div className="EventsWrapper">
                <Navbar />
               <NavbarMobile />

                <div className='bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12'>
                    <div className='flex-grow p-4 sm:p-12'>
                        <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between mb-6">
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900">Tables</h3>
                        </div>
                        {events && events.length > 0 && <ReservationsEventsTable events={events} />}
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default WithAuth(Reservations);