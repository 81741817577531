import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useState } from 'react';

export default function SignUp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const register = async (e) => {
    e.preventDefault();
    setLoading(true);

    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    const confirmpassword = document.getElementById("confirmpassword").value;
    
    if (!name || !email || !password || !confirmpassword) {
      toast.error("Please fill out every field.");
      setLoading(false);
      return;
    }

    if (password.length < 8) {
      toast.error("Password needs to be at least 8 characters!");
      setLoading(false);
      return;
    }

    if (password !== confirmpassword) {
      toast.error("Passwords don't match!");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API}/api/whoppa/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          email,
          password
        })
      });

      const data = await response.json();


      if (response.status === 200) {
        // Store the user token
        localStorage.setItem('token', data.token);
        
        // Redirect to Stripe Connect onboarding
        if (data.content.accountLink) {
          window.location.href = data.content.accountLink;
        } else {
          navigate('/login');
        }
        toast.success('Registration successful!');
      } else if (response.status === 409) {
        toast.error('User already exists.');
      } else {
        toast.error('Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Registration error:', error);
      toast.error('Registration failed. Please try again.');
    }
    
    setLoading(false);
  };

  return (
    <div className="w-full h-dvh bg-slate-900 text-slate-500 flex justify-center items-center">
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="">
          <div className="lg:flex lg:space-x-20">
            {/* Left side */}
            <div className="grow lg:mt-20 mb-12 lg:mb-0 flex flex-col items-center lg:items-start">
              {/* Headline */}
              <h1 className="h2 font-hkgrotesk mb-8 text-center lg:text-left">Start selling tickets today</h1>
              {/* List */}
              <ul className="inline-flex flex-col text-lg text-slate-500 space-y-3">
                <li className="flex items-center">
                  <svg className="w-3 h-3 fill-current text-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Secure payment processing with Stripe</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-3 h-3 fill-current text-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Instant payouts to your bank account</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-3 h-3 fill-current text-emerald-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Fixed €1.79 fee per ticket sold</span>
                </li>
              </ul>
            </div>
            {/* Right side */}
            <div className="relative w-full max-w-md mx-auto">
              {/* Bg gradient */}
              <div className="absolute inset-0 opacity-40 bg-gradient-to-t from-transparent to-slate-800 -z-10" aria-hidden="true" />
              <div className="p-6 md:p-8">
                <div className="font-hkgrotesk text-xl font-bold mb-6">Create your account</div>
                {/* Form */}
                <form onSubmit={register}>
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm text-slate-400 font-medium mb-1" htmlFor="name">
                        Name <span className="text-rose-500">*</span>
                      </label>
                      <input
                        id="name"
                        className="form-input text-sm py-2 w-full"
                        type="text"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm text-slate-400 font-medium mb-1" htmlFor="email">
                        Email <span className="text-rose-500">*</span>
                      </label>
                      <input
                        id="email"
                        className="form-input text-sm py-2 w-full"
                        type="email"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm text-slate-400 font-medium mb-1" htmlFor="password">
                        Password <span className="text-rose-500">*</span>
                      </label>
                      <input
                        id="password"
                        className="form-input text-sm py-2 w-full"
                        type="password"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm text-slate-400 font-medium mb-1" htmlFor="confirmpassword">
                        Confirm Password <span className="text-rose-500">*</span>
                      </label>
                      <input
                        id="confirmpassword"
                        className="form-input text-sm py-2 w-full"
                        type="password"
                        required
                      />
                    </div>
                  </div>
                  <div className="mt-6">
                    <button
                      type="submit"
                      className="btn-sm text-sm text-white bg-indigo-600 hover:bg-indigo-700 w-full shadow-sm group"
                      disabled={loading}
                    >
                      {loading ? 'Creating Account...' : 'Create Account'}
                    </button>
                  </div>
                </form>
                {/* Divider */}
                <div className="flex items-center my-6">
                  <div className="border-t border-slate-800 grow mr-3" aria-hidden="true" />
                  <div className="text-sm text-slate-500 italic">Or</div>
                  <div className="border-t border-slate-800 grow ml-3" aria-hidden="true" />
                </div>
                <div className="text-center">
                  <div className="text-sm text-slate-500">
                    Already have an account?{' '}
                    <a className="font-medium text-indigo-600" href="/login">
                      Sign in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
