import Navbar from '../../components/navbar/Navbar';
import { useEffect, useState } from 'react';
import Footer from '../../components/footer/Footer';
import { fetchWithCredentials, WithAuth } from '../../utils/utils';
import NavbarMobile from '../../components/navbar/NavbarMobile';
import { useParams } from 'react-router-dom';
import TablesTable from '../../components/tables-table/TablesTable';

const ReservationsOverview = () => {
    const [data, setData] = useState(null);
    const params = useParams();

    useEffect(() => {
        getFloorPlan(params.id);
    }, [])

    const getFloorPlan = () => {
        fetchWithCredentials(`/api/whoppa/eventFloorPlan/${params.id}`, {})
        .then(response => {
            setData(response.content);
        })
    }

    return (
        <div className="Events">
            <div className="EventsWrapper">
                <Navbar />
               <NavbarMobile />

                {data && <div className='bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12'>
                    <div className='flex-grow p-4 sm:p-12'>
                        <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between mb-6">
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900">Overview</h3>
                        </div>

                        <div className='flex flex-col gap-4'>
                            <div className='flex flex-col gap-2'>
                                <h4 className='text-lg font-semibold leading-6 text-gray-900'>{data.floorPlan.name}</h4>
                            </div>
                        </div>

                        <TablesTable tables={data.floorPlan.tables} orders={data.orders} eventId={params.id} floorplanId={data.floorPlan._id} ownerId={data.floorPlan.owner} refresh={getFloorPlan} />
                    </div>
                    <Footer />
                </div>}
            </div>
        </div>
    );
}

export default WithAuth(ReservationsOverview);