import { useEffect, useState } from 'react';
import './Home.css';
import { useParams, useLocation } from 'react-router-dom';
import 'animate.css';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import moment from 'moment';

const Home = () => {
  const [event, setEvent] = useState(undefined);
  const [tickets, setTickets] = useState([]);
  const [cart, setCart] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(null);
  const [age, setAge] = useState(18);
  const [gender, setGender] = useState("male");
  const [refund, setRefund] = useState(false);
  const [tablesEnabled, setTablesEnabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refParam = queryParams.get('ref');

  useEffect(() => {
    if (params.id) {
      getEvent(params.id);
    }
  }, [params])

  useEffect(() => {
    if (refParam) {
      localStorage.setItem('refParam', refParam);
    }
  }, [refParam]);

  const getEvent = (id) => {
    fetch(`${process.env.REACT_APP_API}/api/whoppa/getEvent/${id}`, {})
    .then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          console.log(data.content)
            setEvent(data.content)
            setTickets(data.content.tickets.filter(x => x.public))
            const resultObject = data.content.tickets.reduce((acc, obj) => {
              acc[obj._id] = 0;
              return acc;
            }, {});

            setCart(resultObject)
        });
      } else {
        toast.error('Something went wrong.')
      }
    })
  }

  const [errorMessage, setErrorMessage] = useState(null);

  const nextpage = () => {
    let temp = currentPage;

    if (currentPage === 0 && getTotalTickets() < 1) {
      toast.error('Please select at least one ticket')
      return;
    }
    
    if (currentPage === 0 && document.getElementById('terms').checked === false) {
      toast.error('Please accept the terms of service')
      return;
    }

    if (currentPage === 1 && !first) {
      toast.error('Please enter your first name')
      return;
    }
    
    if (currentPage === 1 && !last) {
      toast.error('Please enter your last name')
      return;
    }

    if (currentPage === 1 && !email) {
      toast.error('Please enter your email address')
      return;
    }

    if (currentPage === 1 && !phone) {
      toast.error('Please enter your phone numebr')
      return;
    }

    if (currentPage === 1 && !age) {
      toast.error('Please enter your age')
      return;
    }

    if (currentPage === 1 && !gender) {
      toast.error('Please enter your gender')
      return;
    }

    setCurrentPage(temp + 1);
  }

  const increment = (type) => {
    let temp = cart;
    temp = {
      ...cart,
      [type]: cart[type] + 1
    }
    setCart(temp);
  }

  const decrement = (type) => {
    if (cart[type] === 0) return;
    let temp = cart;
    temp = {
      ...cart,
      [type]: cart[type] - 1
    }
    setCart(temp);
  }

  const handleSubmit = async () => {
    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const res = await fetch(`${process.env.REACT_APP_API}/api/whoppa/createPaymentIntent`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        first,
        last,
        email,
        phone,
        age,
        gender,
        amount: refund ? (getTotal() + (getTotalTickets() * 1.79) + (getTotalTickets() * 1.5)) * 100 : (getTotal() + (getTotalTickets() * 1.79)) * 100,
        refund,
        event,
        cart,
        ref: localStorage.getItem('refParam')
      })
    });

    const result = await res.json();

    const {error} = await stripe.confirmPayment({
      clientSecret: result.clientSecret,
      confirmParams: {
        return_url: `https://www.whoppatix.com/verify/${result.clientSecret}`,
      },
      elements: elements,
    });
    
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      console.log(error)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const paymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true
    }
  };

  const handleFirst = (e) => {
    setFirst(e.target.value)
  }

  const handleLast = (e) => {
    setLast(e.target.value)
  }

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const handlePhone = (e) => {
    setPhone(e.target.value)
  }

  const handleAge = (e) => {
    setAge(e.target.value)
  }

  const handleGender = (e) => {
    setGender(e.target.value)
  }

  const ageOptions = [];

  for (let age = 18; age <= 100; age++) {
    ageOptions.push(
      <option key={age} value={age}>
        {age}
      </option>
    );
  }

  const getTotalTickets = () => {
    let count = 0;
    Object.keys(cart).forEach(key => count += cart[key])
    return count
  }

  const getTotal = () => {
    let count = 0;
    Object.keys(cart).forEach(key => {
      let ticket = tickets.find(x => x._id === key)
      if (ticket) count += ticket.price * cart[key]
    })
    return count
  }
  
  if (!event) return null

  return (
    <div className="tickets-wrapper flex justify-center items-center h-dvh bg-gray-100 bg-[url('https://i.giphy.com/media/66qf3XemfW6P0BUvuu/giphy.gif')] bg-cover bg-center bg-black/50 bg-blend-darken">
      <div className="mx-2 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow w-full sm:w-9/12">
        <div className="px-4 py-5 sm:px-6">
          <div className='flex justify-between flex-col sm:flex-row sm:items-center'>
            <h1 className='font-bold text-3xl'>{event.name}</h1>
            <h1 className='font-bold text-lg sm:text-xl'>{event.location}</h1>
          </div>
          <small>{moment(event.startDate).format('D/M hh:mm A')} - {moment(event.endDate).format('D/M hh:mm A')}</small>
          <p className='text-gray-600'>{event.description}</p>
        </div>
        <div className={currentPage > 0 ? 'px-4 sm:px-6 hidden' : 'px-4 sm:px-6 block'}>
          {tickets.length > 0 && tickets.map(ticket => <div className="flex justify-between items-center py-4">
            <div className="ticket-price">
              <h4 className='font-bold'>{ticket.name}</h4>
              <small style={{ textDecoration: event.disabled ? 'line-through' : '' }}>€{(ticket.price).toFixed(2)} + €1.79 service fee.</small>
            </div>

            <div className="ticket-amount">
              <button onClick={() => decrement(ticket._id)} disabled={event.disabled}>-</button>
              <h4>{cart[ticket._id]}</h4>
              <button onClick={() => increment(ticket._id)} disabled={event.disabled}>+</button>
            </div>
          </div>)}
        </div>
        <div className={currentPage === 1 ? 'px-4 py-5 sm:p-6 block' : 'px-4 py-5 sm:p-6 hidden'}>
          <div className="">
            <form className='animate__animated animate__fadeIn'>
              <div className="form-group-duo my-3 sm:my-0">
                <div className="w-5/12 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label htmlFor="first" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                    First Name
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <input
                    type="text"
                    name="first"
                    id="first"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    value={first}
                    onChange={handleFirst}
                    />
                </div>
                </div>

                <div className="w-5/12 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label htmlFor="last" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                    Last Name
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <input
                    type="text"
                    name="last"
                    id="last"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    value={last}
                    onChange={handleLast}
                    />
                </div>
                </div>
              </div>

              <div className="form-group-duo my-3 sm:my-0">
                <div className="w-5/12 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                    Email
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    value={email}
                    onChange={handleEmail}
                    />
                </div>
                </div>

                <div className="w-5/12 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                    Phone Number
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <input
                    type="tel"
                    name="phone"
                    id="phone"
                    autoComplete="phone"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    value={phone}
                    onChange={handlePhone}
                    />
                </div>
                </div>
              </div>

              <div className="form-group-duo my-3 sm:my-0">
                <div className="w-5/12 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label htmlFor="age" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                      Age
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <select
                      className="block w-full rounded-md border-0 h-10 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      value={age}
                      onChange={handleAge}>
                      {ageOptions.length > 0 && ageOptions}
                    </select>
                  </div>
                </div>

                <div className="w-5/12 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label htmlFor="age" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                      Gender
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <select
                      className="block w-full rounded-md border-0 h-10 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      value={gender}
                      onChange={handleGender}>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="nonbinary">Non-Binary</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className={currentPage === 2 ? 'px-4 py-5 sm:p-6 block' : 'px-4 py-5 sm:p-6 hidden'}>
          <div className="w-full">
            <form onSubmit={handleSubmit}>
              <PaymentElement options={paymentElementOptions} />
              {/* Show error message to your customers */}
              {errorMessage && <div>{errorMessage}</div>}
            </form>
          </div>
        </div>
        <div className={currentPage > 0 ? 'px-4 py-5 sm:p-6 hidden' : 'px-4 py-5 sm:p-6 block'}>
          <div className="flex items-center">
            <input type="checkbox" id="terms" className="mr-4"></input>
            <p>I agree to the terms of service of <span>WhoppaTix</span></p>
          </div>
        </div>
        <div className="px-4 py-4 sm:px-6">
          <div className="flex justify-between items-center">
            <div className="ticket-price">
              {/* <small><input id="refund" type="checkbox" checked={refund} onChange={() => setRefund(!refund)}></input> Refund Policy (€1.50/ticket)</small> */}
              <small>Total (incl. tax & €{(getTotalTickets() * 1.79).toFixed(2)} booking fee)</small>
              {refund ? <h4 className='font-bold'>€{(getTotal() + (getTotalTickets() * 1.79) + (getTotalTickets() * 1.5)).toFixed(2)}</h4> : <h4 className='font-bold'>€{(getTotal() + (getTotalTickets() * 1.79)).toFixed(2)}</h4>}
            </div>

            <div className="">
              <button 
                type="button"
                className={`inline-flex items-center rounded-md ${event.disabled ? 'bg-indigo-200' : 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'}  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
                onClick={() => currentPage === 2 ? handleSubmit() : nextpage()}  disabled={!stripe || !elements || event.disabled}>{currentPage > 1 ? 'Pay' : event.disabled ? 'Closed' : 'Next'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;
