import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';

const ReservationModal = ({ 
  table, 
  onClose, 
  onSubmit, 
  onComplete, 
  floorplan, 
  event, 
  setClientSecret,
  initialStep = 'reservation',
  initialFormData = null,
  setParentStep = null
}) => {
  const [formData, setFormData] = useState(initialFormData || {
    timeOfArrival: new Date(event.startDate),
    guestSize: 1,
    name: '',
    email: '',
    age: '',
    customRequests: '',
    firstName: '',
    lastName: '',
    phone: '',
    gender: 'male'
  });
  const [currentStep, setCurrentStep] = useState(initialStep); 
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  
  const stripe = useStripe();
  const elements = useElements();

  // Update parent component's step state when our step changes
  useEffect(() => {
    if (setParentStep) {
      setParentStep(currentStep);
    }
  }, [currentStep, setParentStep]);

  // Initialize form data from props when component mounts or when initialFormData changes
  useEffect(() => {
    if (initialFormData) {
      setFormData(initialFormData);
    }
  }, [initialFormData]);

  // Initialize step from props
  useEffect(() => {
    setCurrentStep(initialStep);
  }, [initialStep]);

  if (!table) return null;

  // Filter time options to only allow times between event start and end
  const filterTime = (time) => {
    const eventStart = new Date(event.startDate);
    const eventEnd = new Date(event.endDate);
    
    // If the selected date is the start date, only allow times after the start time
    if (time.getDate() === eventStart.getDate() && 
        time.getMonth() === eventStart.getMonth() && 
        time.getFullYear() === eventStart.getFullYear()) {
      return time.getHours() >= eventStart.getHours() && 
             (time.getHours() > eventStart.getHours() || 
              time.getMinutes() >= eventStart.getMinutes());
    }
    
    // If the selected date is the end date, only allow times before the end time
    if (time.getDate() === eventEnd.getDate() && 
        time.getMonth() === eventEnd.getMonth() && 
        time.getFullYear() === eventEnd.getFullYear()) {
      return time.getHours() <= eventEnd.getHours() && 
             (time.getHours() < eventEnd.getHours() || 
              time.getMinutes() <= eventEnd.getMinutes());
    }
    
    // For dates in between, all times are valid
    return true;
  };

  // Handler for when user clicks "Back" button in payment step
  const handleBackToReservation = () => {
    setCurrentStep('reservation');
    if (setParentStep) {
      setParentStep('reservation');
    }
  };

  const handleReservationSubmit = async (e) => {
    e.preventDefault();
    
    // First, make the reservation
    const reservationData = {
      ...formData,
      age: parseInt(formData.age),
      timeOfArrival: formData.timeOfArrival
    };

    // If payment step is being handled by parent component, let it handle API calls
    const success = await onSubmit(reservationData);
    
    if (!success) {
      return;
    }
    
    // If parent is not handling payment intent creation, handle it here
    if (!setParentStep) {
      try {
        // Create a payment intent
        const res = await fetch(`${process.env.REACT_APP_API}/api/whoppa/createPaymentIntent`, {
          method: 'POST',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify({
            first: formData.firstName || formData.name.split(' ')[0] || formData.name,
            last: formData.lastName || formData.name.split(' ').slice(1).join(' ') || '',
            email: formData.email,
            phone: formData.phone || '',
            age: formData.age,
            gender: formData.gender || 'male',
            amount: (table.price * 100) + 499,
            refund: false,
            event: event,
            cart: {},
            ref: localStorage.getItem('refParam'),
            table: true,
            tableId: table._id,
            floorplan: floorplan,
            groupSize: formData.guestSize
          })
        });

        const result = await res.json();
        if (result.clientSecret) {
          setClientSecret(result.clientSecret);
          setCurrentStep('payment');
        } else {
          toast.error('Could not initialize payment');
        }
      } catch (error) {
        console.error('Failed to process reservation:', error);
        toast.error('Failed to process reservation');
      }
    }
  };

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    setIsProcessing(true);

    try {
      // Trigger form validation and wallet collection
      const {error: submitError} = await elements.submit();
      if (submitError) {
        setErrorMessage(submitError.message);
        setIsProcessing(false);
        return;
      }

      // Complete payment when the submit button is clicked
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change the domain in production
          return_url: `${window.location.origin}/verify/${localStorage.getItem('clientSecret')}`,
        },
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(error.message);
        setIsProcessing(false);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        // Note: We can't reach this code in most cases because the page will redirect
      }
    } catch (error) {
      console.error('Payment error:', error);
      setErrorMessage('An unexpected error occurred during payment.');
      setIsProcessing(false);
    }
  };

  const paymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full overflow-scroll max-h-[90vh] ">
        {currentStep === 'reservation' ? (
          <>
            <h2 className="text-xl font-bold">Reserve Table {table.name}</h2>
            <p className="text-gray-600 mb-4">Amount: €{(table.price + 4.99).toFixed(2)}</p>
            <form onSubmit={handleReservationSubmit} className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block mb-1">First Name</label>
                  <input
                    type="text"
                    value={formData.firstName}
                    onChange={(e) => setFormData(prev => ({ ...prev, firstName: e.target.value }))}
                    className="w-full border p-2 rounded"
                    required
                  />
                </div>
                
                <div>
                  <label className="block mb-1">Last Name</label>
                  <input
                    type="text"
                    value={formData.lastName}
                    onChange={(e) => setFormData(prev => ({ ...prev, lastName: e.target.value }))}
                    className="w-full border p-2 rounded"
                    required
                  />
                </div>
              </div>

              <div>
                <label className="block mb-1">Email</label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                  className="w-full border p-2 rounded"
                  required
                />
              </div>
              
              <div>
                <label className="block mb-1">Phone</label>
                <input
                  type="tel"
                  value={formData.phone}
                  onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
                  className="w-full border p-2 rounded"
                  required
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block mb-1">Age</label>
                  <input
                    type="number"
                    value={formData.age}
                    min="18"
                    onChange={(e) => setFormData(prev => ({ ...prev, age: e.target.value }))}
                    className="w-full border p-2 rounded"
                    required
                  />
                </div>
                
                <div>
                  <label className="block mb-1">Number of Guests</label>
                  <input
                    type="number"
                    min="1"
                    max="8"
                    value={formData.guestSize}
                    onChange={(e) => setFormData(prev => ({ ...prev, guestSize: parseInt(e.target.value) }))}
                    className="w-full border p-2 rounded"
                    required
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block mb-1">Gender</label>
                  <select
                    value={formData.gender}
                    onChange={(e) => setFormData(prev => ({ ...prev, gender: e.target.value }))}
                    className="w-full border p-2 rounded"
                    required
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                <div>
                  <label className="block mb-1">Time of Arrival</label>
                  <DatePicker
                    selected={formData.timeOfArrival < new Date(event.startDate) ? new Date(event.startDate) : formData.timeOfArrival > new Date(event.endDate) ? new Date(event.endDate) : formData.timeOfArrival}
                    onChange={(date) => setFormData(prev => ({ ...prev, timeOfArrival: date }))}
                    showTimeSelect
                    timeIntervals={15}
                    filterTime={filterTime}
                    minDate={new Date(event.startDate)}
                    maxDate={new Date(event.endDate)}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className="w-full border p-2 rounded"
                    placeholderText="Select date and time"
                  />
                </div>
              </div>

              <div>
                <label className="block mb-1">Special Requests (birthday, bachelor party, etc.)</label>
                <textarea
                  value={formData.customRequests}
                  onChange={(e) => setFormData(prev => ({ ...prev, customRequests: e.target.value }))}
                  className="w-full border p-2 rounded"
                  rows={3}
                />
              </div>

              <div className="flex space-x-4">
                <button
                  type="submit"
                  className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Continue to Payment
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </>
        ) : (
          <>
            <h2 className="text-xl font-bold mb-4">Complete Payment for Table {table.name}</h2>
            <p className="text-gray-600 mb-4">
              Amount: €{(table.price).toFixed(2)}
            </p>
            <p className="text-gray-600 mb-4">
              Service fee: €4.99
            </p>
            
            {errorMessage && <div className="text-red-600 mb-4">{errorMessage}</div>}
            
            <form onSubmit={handlePaymentSubmit} className="space-y-4">
              {stripe && elements && (
                <>
                  <PaymentElement options={paymentElementOptions} />
                  
                  <div className="mt-4 flex justify-between">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                      onClick={handleBackToReservation}
                      disabled={isProcessing}
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      disabled={isProcessing}
                    >
                      {isProcessing ? "Processing..." : "Pay Now"}
                    </button>
                  </div>
                </>
              )}
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ReservationModal;