import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getStripeAccountLink, getStripeAccountStatus } from '../../services/stripe';

export default function StripeConnectStatus() {
  const [loading, setLoading] = useState(true);
  const [accountStatus, setAccountStatus] = useState(null);

  useEffect(() => {
    fetchAccountStatus();
  }, []);

  const fetchAccountStatus = async () => {
    try {
      const status = await getStripeAccountStatus();
      setAccountStatus(status.content);
    } catch (error) {
      console.error('Error fetching Stripe account status:', error);
      toast.error('Failed to fetch account status');
    } finally {
      setLoading(false);
    }
  };

  const handleOnboarding = async () => {
    try {
      setLoading(true);
      const data = await getStripeAccountLink();
      const { url } = data.content;
      window.location.href = url;
    } catch (error) {
      console.error('Error getting onboarding link:', error);
      toast.error('Failed to start onboarding');
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!accountStatus) {
    return (
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Connect with Stripe
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>Connect your Stripe account to start accepting payments and receive payouts.</p>
          </div>
          <div className="mt-5">
            <button
              type="button"
              onClick={handleOnboarding}
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Connect with Stripe
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!accountStatus.detailsSubmitted || !accountStatus.enabled || !accountStatus.payoutsEnabled) {
    return (
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Complete your Stripe account setup
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>Additional information is required to activate your account.</p>
            {accountStatus.requirements?.currently_due?.length > 0 && (
              <ul className="mt-2 list-disc list-inside">
                {accountStatus.requirements.currently_due.map((requirement) => (
                  <li key={requirement}>{requirement.split('_').join(' ')}</li>
                ))}
              </ul>
            )}
          </div>
          <div className="mt-5">
            <button
              type="button"
              onClick={handleOnboarding}
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Complete account setup
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Stripe account connected
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>Your Stripe account is fully set up and ready to accept payments.</p>
        </div>
        <div className="mt-3 text-sm">
          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Active
          </span>
        </div>
      </div>
    </div>
  );
} 