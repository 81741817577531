import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './Dashboard.css';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { setEvents, setOrders } from '../../redux/infoSlice';
import { fetchWithCredentials, WithAuth } from '../../utils/utils';
import NavbarMobile from '../../components/navbar/NavbarMobile';
import StripeConnectStatus from '../../components/stripe-connect-status/StripeConnectStatus';
import EventsTable from '../../components/events-table/EventsTable';
import DeleteEventDialog from '../../components/delete-event-dialog/DeleteEventDialog';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
};

const getLast7Days = () => {
    const today = new Date();
    const last7Days = [];
  
    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
  
      const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
      const year = (date.getFullYear()).toString(); // Month is 0-indexed
  
      last7Days.push({ day, month, year });
    }
  
    return last7Days;
}

const Dashboard = () => {
    const orders = useSelector((state) => state.infoReducer.orders);
    const events = useSelector((state) => state.infoReducer.events);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [toDeleteEvent, setToDeleteEvent] = useState(null);
    const [data, setData] = useState({
        labels: getLast7Days().map(x => `${x.day}-${x.month}`),
        datasets: [
          {
            label: 'Tickets',
            data: [0, 0, 0, 0, 0, 0, 0],
            borderColor: 'rgb(79, 70, 229)',
            backgroundColor: 'rgba(79, 70, 229)',
          },
        ],
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        getOrders();
        getEvents();
    }, []);

    const getOrders = () => {
        fetchWithCredentials(`/api/whoppa/getOrders`, {})
        .then(response => {
            dispatch(setOrders(response.content));

            const countItemsByDate = {};

            const originalDates = getLast7Days().map(x => `${x.day}-${x.month}`);
            response.content.forEach((obj) => {
                const createdOnDate = new Date(obj.createdOn);
                const day = createdOnDate.getDate().toString().padStart(2, '0'); // Ensure two digits
                const month = (createdOnDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
                const mappedDate = `${day}-${month}`;
                
                // Check if the date is in the originalDates array
                if (originalDates.includes(mappedDate)) {
                    // Increment the count for the date
                    countItemsByDate[mappedDate] = (countItemsByDate[mappedDate] || 0) + obj.tickets.length;
                }
            });

            const countsArray = originalDates.map(date => countItemsByDate[date] || 0);
            handleUpdateData(countsArray);
        });
    };

    const getEvents = () => {
        fetchWithCredentials(`/api/whoppa/getEvents`, {})
        .then(response => {
            dispatch(setEvents(response.content));
        });
    };

    const handleUpdateData = (newData) => {
        setData({
            ...data,
            datasets: [{
                ...data.datasets[0],
                data: newData
            }]
        });
    };

    const onDelete = (event) => {
        setIsModalOpen(true);
        setToDeleteEvent(event);
    };

    const deleteEvent = () => {
        fetchWithCredentials(`/api/whoppa/deleteEvent/${toDeleteEvent._id}`, {})
        .then(response => {
            getEvents();
        });

        setIsModalOpen(false);
        setToDeleteEvent(null);
    };

    const daysLeftUntil = (dateString) => {
        const targetDate = new Date(dateString);
        const currentDate = new Date();
        const differenceMs = targetDate - currentDate;
        const daysLeft = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
        
        return daysLeft;
    }

    return (
        <div className="Events company">
            <div className="EventsWrapper">
                <Navbar />
                <NavbarMobile />
                <div className="bg-gray-100 flex flex-col min-h-screen w-full sm:w-10/12">
                    <div className='flex-grow p-4 sm:p-12'>
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-2xl font-semibold leading-6 text-gray-900">Dashboard</h1>
                            <p className="mt-2 text-sm text-gray-600">
                                An overview of your ticket sales and account status.
                            </p>
                        </div>
                    </div>
                    
                    {/* Stripe Connect Status */}
                    <div className="mt-6 w-fit">
                        <StripeConnectStatus />
                    </div>

                    {/* Sales Chart */}
                    <div className="mt-6 bg-white shadow sm:rounded-lg">
                        <h3 className="text-base font-semibold leading-6 text-gray-900 mb-4">
                            Ticket Sales
                        </h3>
                        <Bar options={options} data={data} />
                    </div>

                    {/* Events Table */}
                    <div className="m-4 sm:m-6 lg:m-8">
                        <EventsTable events={events} onDelete={onDelete} />
                    </div>

                </div>
                    <Footer />
                </div>
            </div>
            {isModalOpen && <DeleteEventDialog
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                onDelete={deleteEvent}
                event={toDeleteEvent}
            />}
        </div>
    );
};

export default WithAuth(Dashboard);