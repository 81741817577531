
import { useNavigate, useParams } from 'react-router-dom';
import './VerifyPayment.css';
import { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { InfinitySpin, RotatingLines } from 'react-loader-spinner';
import { Button } from '@mui/material';
import { FaCheckCircle } from 'react-icons/fa';
import confetti from 'canvas-confetti';

const VerifyPayment = () => {
    const [status, setStatus] = useState("");
    const stripe = useStripe();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params.secret && stripe != null) {
          getIntent(params.secret);
        }
    }, [params, stripe])

    const getIntent = (secret) => {
        stripe
        .retrievePaymentIntent(secret)
        .then(async function(result) {
            if (result.error) {
            } else {
                setStatus(result.paymentIntent.status);

                if (result.paymentIntent.status == 'succeeded') {
                    await fetch(`${process.env.REACT_APP_API}/api/whoppa/verifyOrder/${params.secret}`);
                    confettiFrame();
                }
            }
        });
    }

    const retry = async () => {
        await stripe.confirmBancontactPayment(params.secret, {
        payment_method: {
            billing_details: {
            name: 'David Knetemann',
            },
        },
        // Return URL where the customer should be redirected after the authorization.
        return_url: `${window.location.href}verify/${params.secret}`,
        });
    }

    const duration = 2 * 1000;
    const animationEnd = Date.now() + duration;

    function confettiFrame() {
        const timeLeft = animationEnd - Date.now();

        function randomInRange(min, max) {
            return Math.random() * (max - min) + min;
        }

        confetti({
            particleCount: 1,
            startVelocity: 0,
            ticks: Math.max(200, 500 * (timeLeft / duration)),
            origin: {
                x: Math.random(),
                y: Math.random() * 0.3,
            },
            colors: ['#4f46e5'],
            shapes: ['circle'],
            gravity: randomInRange(0.8, 1),
            scalar: randomInRange(0.8, 1.2),
            drift: randomInRange(-0.4, 0.4),
        });

        if (Date.now() < animationEnd) {
            requestAnimationFrame(confettiFrame);
        }
    }

    return (
        <div className="flex justify-center items-center h-dvh bg-gray-100">
            <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow w-full sm:w-9/12">
                <div className="px-4 py-5 sm:px-6">
                    <h1 className='font-bold text-3xl text-center'>Payment Status</h1>
                </div>
                <div className="px-4 sm:px-6 flex justify-center items-center min-h-60">
                    {!status && <RotatingLines strokeColor="#333" strokeWidth="5" animationDuration="0.75" width='100' />}
                    {status && status === 'succeeded' && <div className="ticket-qr">
                        <FaCheckCircle size="128" id="check" />
                        <h4>Ticket Purchased Succesfully.</h4>
                        <p>You will receive an email momentarily.</p>
                    </div>}
                    {status && (status === 'requires_payment_method' || status === 'requires_action') && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <h2 className='text-3xl'>Something went wrong, please try again.</h2>
                        <button 
                            type="button"
                            className="mt-4 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => retry()}
                        >
                            Try Again
                        </button>
                    </div>}
                    {status && status === 'canceled' && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <h2 className='text-3xl'>Payment was cancelled.</h2>
                        <button 
                            type="button"
                            className="mt-4 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => navigate('/')}
                        >
                            Back
                        </button>
                    </div>}
                </div>
            </div>
        </div>
    )
    return (
        <div className="tickets-wrapper">
            <div className="tickets animate__animated animate__fadeIn">
                <h1>Payment status</h1>
                <div className="verify-payment">
                    {!status && <RotatingLines strokeColor="#333" strokeWidth="5" animationDuration="0.75" width='100' />}
                    {status && status == 'succeeded' && <div className="ticket-qr">
                        <FaCheckCircle size="128" id="check" />
                        <h4>Ticket Purchased Succesfully.</h4>
                        <p>You will receive an email momentarily.</p>
                    </div>}
                    {status && (status == 'requires_payment_method' || status == 'requires_action') && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <h2>Something went wrong, please try again.</h2>
                        <Button onClick={() => retry()} variant="contained" style={{ marginTop: '50px' }}>Try Again</Button>
                    </div>}
                    {status && status == 'canceled' && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <h2>Payment was cancelled.</h2>
                        <Button onClick={() => navigate('/')} variant="contained" style={{ marginTop: '50px' }}>Back</Button>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default VerifyPayment;