import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setAccount } from "../../redux/infoSlice";

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const login = (e) => {
    e.preventDefault();
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    
    if (!email || !password) {
      toast.error("Please fill out every field.")
      return
    }
    
    fetch(`${process.env.REACT_APP_API}/api/whoppa/login`, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
            email,
            password
        })
    })
    .then(response => {
        if (response.status === 200) {
          response.json().then(data => {
              dispatch(setAccount(data.content))
              localStorage.setItem('token', data.content.token)
              navigate('/dashboard')
              toast.success('Logged In!')
          });
        } else if (response.status === 404) {
          toast.error('Account not found.')
        } else if (response.status === 401) {
          toast.error('Wrong password.')
        } else if (response.status === 500) {
          toast.error('Server error.')
        } else {
          toast.error('Something went wrong.')
        }
    });
  }

  return (
    <div className="relative w-full h-dvh mx-auto px-4 sm:px-6 bg-slate-900 text-slate-200">
      <div className="pt-32 pb-12 md:pt-40 md:pb-20">
        {/* Page header */}
        <div className="max-w-3xl mx-auto text-center pb-12">
          <h1 className="h2 font-hkgrotesk">Welcome back!</h1>
        </div>
        {/* Form */}
        <div className="max-w-sm mx-auto">
          <form>
            <div className="space-y-4">
            <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-white">
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
              <div className="flex justify-between">
                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-white">
                  Password
                </label>
                <a className="text-sm font-medium text-indigo-600 ml-2" href="/reset-password">
                    Forgot?
                  </a>
                  </div>
                <div className="mt-2.5">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="password"
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <button className="btn-sm text-sm text-white bg-indigo-600 hover:bg-indigo-700 w-full shadow-sm group" onClick={(e) => login(e)}>
                Sign In
              </button>
            </div>
          </form>
          {/* Divider */}
          <div className="flex items-center my-6">
            <div className="border-t border-slate-800 grow mr-3" aria-hidden="true" />
            <div className="text-sm text-slate-500 italic">Or</div>
            <div className="border-t border-slate-800 grow ml-3" aria-hidden="true" />
          </div>
          {/* Social login */}
          {/* <button className="btn-sm text-sm text-white bg-rose-500 w-full relative flex after:flex-1">
            <div className="flex-1 flex items-center">
              <svg className="w-4 h-4 fill-current text-rose-200 shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.679 6.545H8.043v3.273h4.328c-.692 2.182-2.401 2.91-4.363 2.91a4.727 4.727 0 1 1 3.035-8.347l2.378-2.265A8 8 0 1 0 8.008 16c4.41 0 8.4-2.909 7.67-9.455Z" />
              </svg>
            </div>
            <span className="flex-auto text-rose-50 pl-3">Continue With Google</span>
          </button> */}
          <div className="text-center mt-6">
            <div className="text-sm text-slate-500">
              Don't you have an account?{' '}
              <a className="font-medium text-indigo-600" href="/register">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
